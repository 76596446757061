export const areas = [
  {
    id: 1,
    name: "Alle",
    city: "Alle",
    displayName: "Alle",
    new: false,
    slug: "/",
    hasDiscount: false,
    headerTitle: "Alle",
    hasBoats: true,
    isTaxi: false,
    coupon: "VLOTO2024",
    couponHasKm: false,
  },
  {
    id: 2,
    name: "De Krijgsman",
    city: "Muiden",
    displayName: "De Krijgsman",
    new: false,
    slug: "/krijgsman",
    hasDiscount: false,
    headerTitle: "De Krijgsman",
    hasBoats: true,
    isTaxi: false,
    coupon: "MUIDEN2024",
    couponHasKm: false,
    users: 925,
  },
  {
    id: 3,
    name: "IJburg",
    city: "Amsterdam",
    displayName: "IJburg",
    headerTitle: "IJburg",
    new: false,
    slug: "/ijburg",
    hasDiscount: false,
    hasBoats: false,
    isTaxi: false,
    coupon: "IJBURG2024",
    couponHasKm: false,
    users: 659,
  },
  {
    id: 4,
    name: "Loenen a/d Vecht",
    city: "Loenen a/d Vecht",
    displayName: "Loenen a/d Vecht",
    headerTitle: "Loenen",
    new: false,
    slug: "/loenen-aan-de-vecht",
    hasDiscount: false,
    hasBoats: false,
    isTaxi: false,
    coupon: "LOENEN2024",
    couponHasKm: false,
    users: 56,
  },
  {
    id: 5,
    name: "Muiden Vesting",
    city: "Muiden Vesting",
    displayName: "Muiden Vesting",
    headerTitle: "Muiden",
    new: false,
    slug: "/muiden-vesting",
    hasDiscount: false,
    hasBoats: false,
    isTaxi: false,
    coupon: "MUIDEN2024",
    couponHasKm: false,
    users: 64,
  },
  {
    id: 6,
    name: "Weespersluis",
    city: "Weespersluis",
    displayName: "Weespersluis",
    headerTitle: "Weespersluis",
    new: false,
    slug: "/weespersluis",
    hasDiscount: false,
    hasBoats: false,
    isTaxi: false,
    coupon: "WEESPERSLUIS2024",
    couponHasKm: false,
    users: 537,
  },
  {
    id: 7,
    name: "Amsterdam RAI",
    city: "Amsterdam",
    displayName: "RAI",
    headerTitle: "RAI",
    new: true,
    slug: "/huurtaxi",
    hasDiscount: false,
    hasBoats: false,
    isTaxi: true,
    couponHasKm: false,
  },
  {
    id: 8,
    name: "Zeeburgereiland",
    city: "Amsterdam",
    displayName: "Zeeburgereiland",
    headerTitle: "Zeebur...",
    new: false,
    slug: "/zeeburgereiland",
    hasDiscount: false,
    hasBoats: false,
    isTaxi: false,
    coupon: "ZEEBURG2024",
    couponHasKm: false,
    users: 231,
  },
  {
    id: 9,
    name: "Haarlem Elements",
    city: "Haarlem",
    displayName: "Haarlem Elements",
    headerTitle: "Elemen...",
    new: true,
    slug: "/elements",
    hasDiscount: false,
    hasBoats: false,
    isTaxi: false,
    coupon: "HAARLEM24",
    couponHasKm: false,
    users: 67,
  },
  {
    id: 10,
    name: "De Houthavens",
    city: "Amsterdam",
    displayName: "De Houthavens",
    headerTitle: "De Houthavens",
    new: true,
    slug: "/houthavens",
    hasDiscount: false,
    hasBoats: false,
    isTaxi: false,
    coupon: "HOUTHAVENS2024",
    couponHasKm: true,
    users: 59,
  },
  {
    id: 11,
    name: "Driemond",
    city: "Amsterdam",
    displayName: "Driemond",
    headerTitle: "Driemond",
    new: true,
    slug: "/driemond",
    hasDiscount: false,
    hasBoats: false,
    isTaxi: false,
    coupon: "DRIEMOND2024",
    couponHasKm: true,
    users: 24,
  },
  {
    id: 12,
    name: "de Vondelbuurt",
    city: "Amsterdam",
    displayName: "Vondelbuurt",
    headerTitle: "Vondelbuurt",
    new: true,
    slug: "/vondelbuurt",
    hasDiscount: false,
    hasBoats: false,
    isTaxi: false,
    coupon: "VONDELBUURT2024",
    couponHasKm: true,
    users: 22,
    prices: {
      intro: {
        pricePerMonth: "0",
        pricePerKm: "0,36",
        pricePerHour: "6",
      },
      basis: {
        freeHours: "10",
        pricePerMonth: "60",
        pricePerKm: "0,36",
        pricePerHour: "5,50",
      },
      plus: {
        freeHours: "30",
        pricePerMonth: "130",
        pricePerKm: "0,36",
        pricePerHour: "4",
      },
      pro: {
        freeHours: "80",
        pricePerMonth: "280",
        pricePerKm: "0,36",
        pricePerHour: "3,50",
      },
    },
  },
];

export const vehicleTypes = [
  {
    id: 1,
    type: "car",
  },
  {
    id: 2,
    type: "boat",
  },
  {
    id: 3,
    type: "taxi",
  },
];
